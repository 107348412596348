<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Accessories</h2>
                     <nav aria-label="breadcrumb">
                         <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
         Home
        </router-link></li>
                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'setup_guidline' }">Setup Guidelines</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'seating' }">Seating</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'desk' }">Desk</router-link>
                           </li>

                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'desktop_items' }">Desktop Items</router-link>
                           </li>

                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'screens' }">Screens</router-link>
                           </li>
                           
                           <li class="breadcrumb-item active" aria-current="page"> Accessories</li>

                           
                           
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- pagination Starts -->
      <section class="pagination_sec">
         <div class="pagination-nav">
            <div class="container-fluid custome_container">
               <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="row justify-content-between">
                        <div class="col-md-12">
                           <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-between">
                                 <li class="page-item">
                                    <router-link :to=" { name: 'screens' }" class="page-link border-0 text_black" aria-label="Previous">
                                       <span aria-hidden="true">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631" viewBox="0 0 43.575 29.631">
                                          <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                            <path id="Path_30" data-name="Path 30" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1744.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                            <path id="Path_31" data-name="Path 31" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1728.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                          </g>
                                       </svg>
                                          Previous
                                       </span>
                                    </router-link>
                                 </li>
                                 <li class="page-item">
                                    <router-link :to=" { name: 'lighting' }"
                                       class="page-link border-0 text_black" aria-label="Next">
                                       <span aria-hidden="true" class="font_bold">
                                          <span class="text_primary">Up Next<span
                                                class="mobile_hide">: </span></span><span class="mobile_hide">
                                             Lighting</span>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 33.575 29.631" style="margin-left:5px;">
                                             <g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)">
                                               <path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                               <path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                             </g>
                                          </svg>
                                       </span>
                                    </router-link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- pagination Ends -->
      <!-- image text start -->
      <section class="image_text_sec bg_grey pt_50 pb_50 mt_30">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="row">
                     <div class="col-md-6">
                        <div class="image_left_side">
                           <img src="../assets/images/accessories_banner.png" alt="img">
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="image_text_right_side mw-100">
                           <h2 class="mb_22">Ergo is simpler than you think.</h2>
                           <p class="mb_40">
                            Ergonomics, simply put, is being smart about how you work. It’s about empowering yourself to take control of your environment. And the good news is, a little often goes a long way. 
                           </p>
                           <p class="mb_20">
                             So, don’t forget about the little things like footrests, back cushions, monitor risers, phone stands, speaker microphones and blue light glasses.
                           </p>  
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="text-center down_to_section newarrow">
            <a   v-scroll-to="{
     el: '#accordian_secc',
     duration: 500,
     easing: 'linear',
     offset: -350,
 }">
                              <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
  <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
    <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11180.239 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
    <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11150.357 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
  </g>
</svg>
            </a>
         </div>
      </section>
      
      <!-- image text end -->
      <!-- Accordion  start -->
      <section id="accordian_secc" class="accordion_sec my_80">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-8">
                  <div class="accordion" id="accordionExample">
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                              <img src="../assets/images/accessories_icon_1.png" alt="img"> What kind of accessories should I consider?
                           </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                             <div class="claim_text_box">
                                <ul class="pl_18 mb_0 text_balticsea">
                                <li><p class="mb_14"><span class="font_bold">Is your monitor or screen too low?</span> If so, risers work wonders.</p></li>
                                <li><p class="mb_14"><span class="font_bold">Are your feet dangling?</span> Fill the gap with a footrest.</p></li>
                                <li><p class="mb_14"><span class="font_bold">Is your back strained?</span> A back support will do the trick.</p></li>
                                <li><p class="mb_14"><span class="font_bold">Sitting too low?</span> Try a seat cushion for more height.</p></li>
                                <li><p class="mb_14"><span class="font_bold">Is your phone propped up</span> so you don’t have to bend down to see it?</p></li>
                                <li><p class="mb_14"><span class="font_bold">Do you work on your couch?</span> Consider a lap desk to accommodate your laptop, keyboard and mouse.  This also works well if you use your TV as your monitor!</p></li>
                             </ul>
                            </div>
                              
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <img src="../assets/images/accessories_icon_2.png" alt="img"> Are there DIY accessories that work?
                           </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <p>Yes!  In fact, many household items work beautifully as ergonomic accessories.  For instance, books and small boxes work great as monitor risers.  Need a footrest?  Try using a ream of paper.  If you need extra back support, small pillows and rolled up towels work wonders. </p> 
                              
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Accordion  end -->
      <!-- more WFH tips start  -->
      <section class="more_lab_tips bg_grey pt_42">
         <div class="container">
            <div class="row justify-content-center ">
               <div class="col-md-12 col-xl-11 px_20">
                  <div class="row">
                     <div class="col-md-12">
                        <h4 class="heading_4 font_bold mb-0">More setup guidelines</h4>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'seating' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/seating.png" alt="img" class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Seating</h6>
                                 <p>Ergo has your back (literally)  no matter where you sit. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'desk' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/setup_guidline4.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Desk </h6>
                                 <p>Learn to set yourself up well at any desk you use.  </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'desktop_items' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/setup_guidline3.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Desktop Items</h6>
                                 <p>How and what you place on your desk impacts your work style. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'screens' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/setup_guidline2.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Screens</h6>
                                 <p>Wanna have great posture and be productive?  Learn how. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'accessories' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/setup_guidline1.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Accessories</h6>
                                 <p>It’s all in the details.  Learn how accessories can help. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'lighting' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/setup_guidline6.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Lighting</h6>
                                 <p>Learn how to use light to improve your output. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- more WFH tips end  -->
   </div>
</template>

<script>
   export default {
      name: "Seating",
      mounted(){
         document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
         if (
          localStorage.getItem("userToken") == null &&
          localStorage.getItem("employeeEmail") == null
        ) {
         this.$router.push({ name: "login" }).catch(() => true);
        }            
      },
   }
</script>